import React, { useState } from "react";
import Img from "gatsby-image";
import Slider from "react-slick";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useLocation } from "@reach/router";
import References from "../components/references";
import appStore from "../images/aivalable-on-the-app-store.svg";
import googlePlay from "../images/get-it-on-google-play.svg";

import {
  RICH_TEXT_RENDER_OPTIONS_PROJECT,
  RICH_TEXT_RENDER_OPTIONS_HOW_WE_GOT_THERE,
  RICH_TEXT_RENDER_OPTIONS_THE_REAL_VALUE,
} from "../constants";

import ArrowBackwardIcon from "../images/icons/arrow_backward.inline.svg";
import ArrowForwardIcon from "../images/icons/arrow_forward.inline.svg";

const ImageGallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const settings = {
    className: "pt-4",
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 3.1,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (index) => setCurrentIndex(index),
    appendDots: () => (
      <div>
        <div className="container flex justify-end">
          <div className="text-gray px-4">
            {currentIndex + 1}/{images.length}
          </div>
        </div>
      </div>
    ),
  };

  return (
    <Slider {...settings}>
      {images.map((img) => (
        <div className="relative rounded-sm overflow-hidden w-full pb-10/16 bg-gray-darkest focus:outline-none cursor-pointer">
          <div className="absolute h-full w-full pr-4 lg:pr-6">
            <Img
              fluid={img.localFile.childImageSharp.fluid}
              alt={img.description || img.title}
              className="h-full w-full object-cover"
            />
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default function ProjectDetail(props) {
  const { project, isPage } = props;
  const { pathname } = useLocation();
  const intl = useIntl();

  return (
    <>
      <header className="pt-32 md:pt-52 pb-16 lg:pb-16 mb-6 md:mb-10 lg:mb-12 xl:mb-16 relative">
        <div className="absolute left-1/2 ml-40 right-0 top-0 bottom-1/3 lg:bottom-8 z-0 bg-gradient-to-b to-purple-dark from-blue-dark-a0 opacity-50 rounded-bl-5xl" />
        <div className="container grid gird-cols-1 lg:grid-cols-2 gap-6">
          <div className="order-2 lg:order-1 flex flex-col items-start pt-6 lg:pt-0">
            {isPage && (
              <Link
                to="/projects"
                className="flex items-center text-gray transition-colors bg-clip-text group hover:text-white "
              >
                <ArrowBackwardIcon className="flex-shrink-0 fill-current group-hover:text-white transition duration-500 ease-in-out transform group-hover:-translate-x-2" />
                <span className="pl-2 group-hover:text-white transition-colors pt-0.5">
                  <FormattedMessage
                    id="projects.back_to_projects"
                    defaultMessage="All Projects"
                  />
                </span>
              </Link>
            )}

            <h1
              className={`${
                isPage
                  ? "text-5xl lg:text-6xl xl:text-7xl"
                  : "text-4xl lg:text-5xl xl:text-6xl"
              } font-600 leading-none max-w-lg pb-6 lg:pb-10 mt-6 lg:mt-10`}
            >
              {project.title}
            </h1>
            {project.excerpt ? (
              <p className="max-w-md xl:max-w-lg md:text-lg lg:text-xl leading-loose text-gray-mid lg:pr-6 xl:pr-0 pb-8 lg:pb-16 md:pb-10">
                {project.excerpt.excerpt}
              </p>
            ) : null}
            {project.webUrl ? (
              <a
                href={project.webUrl}
                rel=" noopener noreferrer"
                target="_blank"
                className="btn bg-white-a10 inline-flex group"
              >
                <span className="pr-2">See Live Version</span>
                <ArrowForwardIcon className="flex-shrink-0 fill-current transition duration-500 ease-in-out transform group-hover:translate-x-2" />
              </a>
            ) : project.appStoreUrl || project.googlePlayUrl ? (
              <div className="flex space-x-3">
                {project.appStoreUrl ? (
                  <a
                    href={project.appStoreUrl}
                    rel=" noopener noreferrer"
                    target="_blank"
                    className="inline-block mb-6 w-40"
                  >
                    <img src={appStore} />
                  </a>
                ) : null}
                {project.googlePlayUrl ? (
                  <a
                    href={project.googlePlayUrl}
                    rel=" noopener noreferrer"
                    target="_blank"
                    className="inline-block mb-6 w-40"
                  >
                    <img src={googlePlay} />
                  </a>
                ) : null}
              </div>
            ) : isPage ? (
              <AnchorLink
                to={`${pathname}#main`}
                className="btn bg-white-a10 inline-flex group"
              >
                <span className="pr-2">Read More</span>
                <ArrowForwardIcon className="flex-shrink-0 fill-current transition duration-500 ease-in-out transform group-hover:translate-x-2" />
              </AnchorLink>
            ) : null}
          </div>
          <div className="order-1 lg:order-2 lg:absolute lg:right-0 lg:left-1/2 xl:static">
            {project.previewImage ? (
              <div className="relative rounded-sm overflow-hidden w-full pb-10/16 bg-gray-darkest">
                <div className="absolute h-full w-full">
                  <Img
                    fluid={project.previewImage.localFile.childImageSharp.fluid}
                    alt={
                      project.previewImage.description ||
                      project.previewImage.title
                    }
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </header>

      <div id="main">
        {project.originalState || project.desiredState ? (
          <div className="container grid grid-cols-1 lg:grid-cols-2 gap-12 pb-12 md:pb-16 xl:pb-20">
            {project.originalState ? (
              <div className="max-w-lg text-gray">
                <h2 className="text-purple-light font-600 pb-6">
                  {project.originalState.title}
                </h2>
                {documentToReactComponents(
                  project.originalState.markup.json,
                  RICH_TEXT_RENDER_OPTIONS_PROJECT
                )}
              </div>
            ) : null}
            {project.desiredState ? (
              <div className="max-w-lg text-gray">
                <h2 className="text-purple-light font-600 pb-6">
                  {project.desiredState.title}
                </h2>
                {documentToReactComponents(
                  project.desiredState.markup.json,
                  RICH_TEXT_RENDER_OPTIONS_PROJECT
                )}
              </div>
            ) : null}
          </div>
        ) : null}

        {project.reference ? (
          <div className="pb-16 md:pb-20 xl:pb-24">
            <References references={project.reference} />
          </div>
        ) : null}

        {project.howWeGotThere || project.theRealValue ? (
          <div className="relative container md:max-w-none pb-12 md:pb-16 xl:pb-20 px-0">
            {project.howWeGotThere ? (
              <div className="relative px-4 md:px-0 -mt-4 md:-mt-8 lg:-mt-10">
                <div className="grid container grid-cols-1 md:grid-cols-2 lg:grid-cols-5 py-8 md:py-12 lg:py-16 relative z-10">
                  <div className="absolute top-24 md:top-12 lg:top-16 mt-2 bottom-0 left-6 md:left-1/2 lg:left-2/5 md:ml-2 lg:-ml-1 w-px bg-blue bg-gradient-to-b from-purple to-blue" />
                  <h2 className="lg:col-span-2 text-3xl md:text-4xl xl:text-5xl max-w-sm xl:max-w-md pb-6 pl-8 lg:pr-12">
                    {project.howWeGotThere.title}
                  </h2>
                  <div className="lg:col-span-3 max-w-xl lg:-ml-6 pr-4 lg:pr-0 relative z-10">
                    {documentToReactComponents(
                      project.howWeGotThere.markup.json,
                      RICH_TEXT_RENDER_OPTIONS_HOW_WE_GOT_THERE
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {project.theRealValue ? (
              <div className="relative px-4 md:px-0 -mb-6 md:-mb-10 lg:-mb-12">
                <div className="absolute left-0 md:left-0 right-0 h-full lg:rounded-tl-5xl bg-gradient-to-b from-purple-dark to-blue-dark-a0 opacity-60 blend-lighten" />
                <div className="grid container grid-cols-1 md:grid-cols-2 lg:grid-cols-5 py-8 md:py-12 lg:py-16 relative z-10">
                  <div className="absolute top-0 -bottom-10 lg:-bottom-20 left-6 md:left-1/2 lg:left-2/5 md:ml-2 lg:-ml-1 w-px bg-blue bg-gradient-to-b from-blue to-black" />
                  <h2 className="lg:col-span-2 text-3xl md:text-4xl xl:text-5xl max-w-md pb-6 pl-8 lg:pr-12">
                    {project.theRealValue.title}
                  </h2>
                  <div className="lg:col-span-3 max-w-xl lg:-ml-6 pr-4 lg:pr-0 relative z-10">
                    {documentToReactComponents(
                      project.theRealValue.markup.json,
                      RICH_TEXT_RENDER_OPTIONS_THE_REAL_VALUE
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        {project.genericSection &&
          project.genericSection.map((section, index) => {
            return (
              <div className="container pb-16 md:pb-20 xl:pb-24 last:pb-0">
                {section.title && (
                  <h2 className="text-2xl md:text-3xl lg:text-5xxl pb-6 lg:pb-12">
                    {section.title}
                  </h2>
                )}
                <div className=" grid grid-cols-1 lg:grid-cols-2 gap-12">
                  {section.markup ? (
                    <div className="max-w-lg">
                      {documentToReactComponents(
                        section.markup.json,
                        RICH_TEXT_RENDER_OPTIONS_PROJECT
                      )}
                    </div>
                  ) : null}
                  {section.markup2 ? (
                    <div className="max-w-lg">
                      {documentToReactComponents(
                        section.markup2.json,
                        RICH_TEXT_RENDER_OPTIONS_PROJECT
                      )}
                    </div>
                  ) : null}
                </div>
                {!section.imageGallery ? null : section.imageGallery.length ===
                  1 ? (
                  <Img
                    fluid={
                      section.imageGallery[0].localFile.childImageSharp.fluid
                    }
                    alt={
                      section.imageGallery[0].description ||
                      section.imageGallery[0].title
                    }
                  />
                ) : (
                  <ImageGallery images={section.imageGallery} />
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}
