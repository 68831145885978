import React from "react";
import Slider from "react-slick";
import Img from "gatsby-image";
import ArrowForwardIcon from "../images/icons/arrow_forward.inline.svg";
import ArrowBackwardIcon from "../images/icons/arrow_backward.inline.svg";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute -top-12 right-4 md:right-6 z-10 focus:outline-none"
      aria-label="Previous"
      onClick={onClick}
    >
      <ArrowForwardIcon className="fill-current text-gray-darker hover-hover:text-gray-purple hover-hover:hover:text-white" />
    </button>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute -top-12 right-12 md:right-14 z-10 focus:outline-none"
      aria-label="Next"
      onClick={onClick}
    >
      <ArrowBackwardIcon className="fill-current text-gray-darker hover-hover:text-gray-purple hover-hover:hover:text-white" />
    </button>
  );
};

const References = ({ references = [] }) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return !references.length ? null : references.length === 1 ? (
    <div className="bg-gradient-to-tl from-purple-l1 to-purple-l2 blend-lighten w-full py-8 md:py-12 lg:py-16 xl:py-20 h-full flex items-center">
      <div className="container flex flex-col lg:flex-row">
        <h4 className="text-black font-600 md:pr-10 lg:pr-20 xl:pr-32 pb-4 md:pb-7 lg:pb-10 xl:pb-12">
          Testimonial
        </h4>
        <div className="flex flex-col justify-center">
          <p className="text-xl md:text-2xl lg:text-2x xl:text-3xl max-w-4xl mx-auto leading-relaxed pb-6 md:pb-10">
            {"“"}
            {references[0].quote.quote}
            {"”"}
          </p>
          <div className="flex items-center">
            <Img
              fixed={references[0].avatar.localFile.childImageSharp.fixed}
              alt={
                references[0].avatar.description || references[0].avatar.title
              }
              className="rounded-full flex-shrink-0"
            />
            <p className="pl-4">{references[0].author}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Slider {...settings}>
      {references.map((reference) => (
        <div
          key={reference.id}
          className="focus:outline-none -ml-4 pl-4 md:-ml-6 md:pl-6"
        >
          <div className="bg-gray-darkest2 blend-lighten w-full py-6 md:py-10 lg:py-12 xl:py-16 h-full flex items-center">
            <div className="flex flex-col justify-center px-4 md:px-10 lg:px-12 xl:px-16">
              <p className="lg:text-lg xl:text-xl max-w-4xl mx-auto leading-relaxed pb-6 md:pb-10">
                {"“"}
                {reference.quote.quote}
                {"”"}
              </p>
              <div className="flex items-center">
                <Img
                  fixed={reference.avatar.localFile.childImageSharp.fixed}
                  alt={reference.avatar.description || reference.avatar.title}
                  className="rounded-full flex-shrink-0"
                />
                <p className="pl-4 text-gray-mid">{reference.author}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default References;
